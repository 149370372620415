// api.js

import axios from 'axios';

const API_URL = 'https://api.step.testpromo.ru';

export const fetchSettings = async () => {
    const endpoint = `${API_URL}/api/site/settings`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching settings", error);
        throw error;
    }
};

export const fetchOtherData = async () => {
    const endpoint = `${API_URL}/api/other-endpoint`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching other data", error);
        throw error;
    }
};

export const fetchWinners = async () => {
    const endpoint = `${API_URL}/api/quiz/top-winnings`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching winners", error);
        throw error;
    }
};

export const fetchFAQ = async () => {
    const endpoint = `${API_URL}/api/feedback/faq`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching FAQ:", error);
        throw error;
    }
};

export const sendFeedback = async (email, name, text) => {
    const endpoint = `${API_URL}/api/feedback/send`;
    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('name', name);
    formData.append('text', text);

    try {
        const response = await axios.post(endpoint, formData);
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response);
        throw error;
    }
};