<template>
  <footer>
    <div class="footer">
      <div class="footer-logo">
        <a href="#">
          <img src="@/assets/img/logo.svg" alt="">
        </a>
        2023 © PROMO STEP
      </div>
      <div class="footer-nav">
        <p><a href="#">Пользовательское соглашение</a> <a href="#">Правила акции</a></p>
        <p>Организатор акции ООО «Арт-Композишн»</p>
      </div>
      <div class="footer-social">
        <a href="#">
          <img src="@/assets/img/social-tg.svg" alt="">
        </a>
        <a href="#">
          <img src="@/assets/img/social-vk.svg" alt="">
        </a>
        <a href="#">
          <img src="@/assets/img/social-ok.svg" alt="">
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style></style>