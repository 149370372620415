<template>
  <div id="app">
    <AppHeader />
    <main>
      <!-- Ваш основной контент -->
      <div v-if="loading">Загрузка...</div>
      <div v-else-if="error">Произошла ошибка: {{ error.message }}</div>
      <div v-else>
        <MainContent />
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import AppFooter from '@/components/Footer.vue';
import MainContent from "@/components/MainContent.vue";

export default {
  name: 'App',
  components: {MainContent, AppFooter, AppHeader },
};
</script>

<style lang="scss">
@import '@/assets/scss/App.scss';
</style>