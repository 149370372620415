<template>
  <div>
    <section id="main">
      <div class="main">
        <div class="main-left">
          <span class="main-left__title">СТЕП</span>
          <span class="main-left__sub-title">РОЯЛ</span>
        </div>
        <div class="main-timer">
          <!-- Таймер акции -->
          <div v-if="state === 'beforeStart'">
            <div class="timer-title">
              До начала акции:
            </div>
            <div class="timer-detail-wrap">
              <div class="timer-detail">
                <div class="timer-detail-top">
                  <span>{{ timeUntilStart.days.digit1 }}</span>
                  <span>{{ timeUntilStart.days.digit2 }}</span>
                </div>
                <div class="timer-detail-bottom">
                  Дней
                </div>
              </div>
              <div class="timer-detail">
                <div class="timer-detail-top">
                  <span>{{ timeUntilStart.hours.digit1 }}</span>
                  <span>{{ timeUntilStart.hours.digit2 }}</span>
                </div>
                <div class="timer-detail-bottom">
                  Часов
                </div>
              </div>
              <div class="timer-detail">
                <div class="timer-detail-top">
                  <span>{{ timeUntilStart.minutes.digit1 }}</span>
                  <span>{{ timeUntilStart.minutes.digit2 }}</span>
                </div>
                <div class="timer-detail-bottom">
                  Минут
                </div>
              </div>
            </div>

            <div class="timer-btn">
              <button class="button button-yellow">Участвовать</button>
            </div>
          </div>
          <div v-else-if="state === 'during'">
            <div class="timer-title">
              До окончания акции:
            </div>
            <div class="timer-detail-wrap">
              <div class="timer-detail">
                <div class="timer-detail-top">
                  <span>{{ timeUntilEnd.days.digit1 }}</span>
                  <span>{{ timeUntilEnd.days.digit2 }}</span>
                </div>
                <div class="timer-detail-bottom">
                  Дней
                </div>
              </div>
              <div class="timer-detail">
                <div class="timer-detail-top">
                  <span>{{ timeUntilEnd.hours.digit1 }}</span>
                  <span>{{ timeUntilEnd.hours.digit2 }}</span>
                </div>
                <div class="timer-detail-bottom">
                  Часов
                </div>
              </div>
              <div class="timer-detail">
                <div class="timer-detail-top">
                  <span>{{ timeUntilEnd.minutes.digit1 }}</span>
                  <span>{{ timeUntilEnd.minutes.digit2 }}</span>
                </div>
                <div class="timer-detail-bottom">
                  Минут
                </div>
              </div>
            </div>

            <div class="timer-btn">
              <button class="button button-yellow">Участвовать</button>
            </div>
          </div>
          <div v-else>
            <div class="timer-title">
              Акция завершена
            </div>
          </div>
        </div>
        <div class="main-right">
          <span class="main-right__title">Выигрывай<br>с орехами!</span>
        </div>
      </div>
      <div class="main-img">
        <div class="main-img__left">
          <img src="@/assets/img/maskot.png"
               srcset="@/assets/img/maskot@2x.png 2x" alt="">
        </div>
        <div class="main-img__right">
          <img src="@/assets/img/gift.png"
               srcset="@/assets/img/gift@2x.png 2x" alt="">
        </div>
      </div>
    </section>

    <section id="prizes">
      <div class="section-title-wrap">
        <div class="section-title">
          ПРИЗЫ
        </div>
      </div>
      <div class="prizes-list">
        <div class="prizes-list-item">
          <div class="prizes-list-item__marquee">
            <ul class="marquee-list">
              <li class="marquee-list__item">Супер роял приз</li>
              <li class="marquee-list__item">Супер роял приз</li>
              <li class="marquee-list__item">Супер роял приз</li>
            </ul>
          </div>
          <div class="prizes-list-item__img">
            <img src="@/assets/img/prizes-1.png" alt="">
          </div>
          <div class="prizes-list-item__name">
            Apple
            <br>iPhone 15 pro
          </div>
        </div>
        <div class="prizes-list-item">
          <div class="prizes-list-item__marquee">
            <ul class="marquee-list">
              <li class="marquee-list__item">Каждую неделю</li>
              <li class="marquee-list__item">Каждую неделю</li>
              <li class="marquee-list__item">Каждую неделю</li>
            </ul>
          </div>
          <div class="prizes-list-item__img">
            <img src="@/assets/img/prizes-2.png" alt="">
          </div>
          <div class="prizes-list-item__name">
            Apple
            <br>iPad 10.9
          </div>
        </div>
        <div class="prizes-list-item">
          <div class="prizes-list-item__marquee">
            <ul class="marquee-list">
              <li class="marquee-list__item">Каждый день</li>
              <li class="marquee-list__item">Каждый день</li>
              <li class="marquee-list__item">Каждый день</li>
            </ul>
          </div>
          <div class="prizes-list-item__img">
            <img src="@/assets/img/prizes-3.png" alt="">
          </div>
          <div class="prizes-list-item__name">
            Сертификаты
            <br>OZON
          </div>
        </div>
      </div>
    </section>

    <section id="how-register">
      <div class="kubik">
        <img src="@/assets/img/kubik.png"
             srcset="@/assets/img/kubik@2x.png 2x" alt="">
      </div>
      <div class="section-title-wrap">
        <div class="section-title section-title--long">
          Как принять участие
        </div>
      </div>
      <div class="how-register">
        <div class="how-register-step">
          <div class="how-register-step__img">
            <div class="how-register-step__number">1</div>
            <img src="@/assets/img/step-1.png"
                 srcset="@/assets/img/step-1@2x.png 2x" alt="">
          </div>
          <div class="how-register-step__title">
            Купи продукт
          </div>
          <div class="how-register-step__text">
            Покупай с 23 октября по 24 декабря товар, участвующий в акции
          </div>
        </div>
        <div class="how-register-step">
          <div class="how-register-step__img">
            <div class="how-register-step__number">2</div>
            <img src="@/assets/img/step-2.png"
                 srcset="@/assets/img/step-2@2x.png 2x" alt="">
          </div>
          <div class="how-register-step__title">
            Загрузи чек
          </div>
          <div class="how-register-step__text">
            Отправь свой чек, соответствующий правилам, на проверку
          </div>
        </div>
        <div class="how-register-step">
          <div class="how-register-step__img">
            <div class="how-register-step__number">3</div>
            <img src="@/assets/img/step-3.png"
                 srcset="@/assets/img/step-3@2x.png 2x" alt="">
          </div>
          <div class="how-register-step__title">
            Выиграй приз
          </div>
          <div class="how-register-step__text">
            Получи шанс выиграть сертификат Ozon и участвуй в розыгрыше главных призов
          </div>
        </div>
      </div>
      <div class="how-register-btn">
        <button class="button button-yellow">Загрузить чек</button>
      </div>

      <div class="slot">
        <div class="fishka">
          <img src="@/assets/img/fishka.png"
               srcset="@/assets/img/fishka@2x.png 2x" alt="">
        </div>

        <div class="slot__left">
          <div class="section-title-wrap">
            <div class="section-title section-title--middle">
              Степ Роял
            </div>
          </div>
          <p>Каждый загруженный чек дарит тебе шанс покрутить барабан и выиграть сертификат Ozon на <span>500</span> <span>1000</span> или <span>3000</span> рублей, классный стикерпак в Telegram, а также зажигательный танец Мистера Ореха!</p>
          <p><button class="button button-yellow">Загрузить чек</button></p>
        </div>
        <div class="slot__right">
          <div class="slot-bubble">
            Посмотрим, какой
            <br>ты везучий! Тяни рычаг!
            <br>Поймай удачу!
          </div>
          <button class="slot-btn" @click="slotBtn">
            <svg width="109" height="397" viewBox="0 0 109 397" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g class="slot-btn-position" :class="{ 'is-active': activeSlotIndex === 0 }">
                <path d="M29.9779 53.0928L0 126.11C6.66855 143.096 9.07438 153.965 9.95688 176.751L57.3862 62.086C45.3987 63.4389 37.0505 59.8612 29.9779 53.0928Z" fill="url(#paint2_linear_2323_8297)"/>
                <g filter="url(#filter1_i_2323_8297)">
                  <ellipse cx="52.5266" cy="31.3243" rx="31.6848" ry="31.3243" fill="#F20008"/>
                  <ellipse cx="52.5266" cy="31.3243" rx="31.6848" ry="31.3243" fill="url(#paint3_radial_2323_8297)" fill-opacity="0.89"/>
                </g>
              </g>
              <g class="slot-btn-position" :class="{ 'is-active': activeSlotIndex === 1 }">
                <path d="M10.8418 182.465L44.208 180.465V207.585L10.8418 205.585C11.6894 196.565 11.6657 191.485 10.8418 182.465Z" fill="url(#paint0_linear_2323_8297)"/>
                <g filter="url(#filter0_i_2323_8297)">
                  <ellipse cx="67.1843" cy="190.526" rx="31.6848" ry="31.3243" fill="#F20008"/>
                  <ellipse cx="67.1843" cy="190.526" rx="31.6848" ry="31.3243" fill="url(#paint1_radial_2323_8297)" fill-opacity="0.89"/>
                </g>
              </g>
              <g class="slot-btn-position slot-btn-position--custom" :class="{ 'is-active': activeSlotIndex === 2 }">
                <path d="M29.9779 333.838L0 260.82C6.66855 243.835 9.07438 232.966 9.95688 210.179L57.3862 324.845C45.3987 323.492 37.0505 327.07 29.9779 333.838Z" fill="url(#paint4_linear_2323_8297)"/>
                <g filter="url(#filter2_i_2323_8297)">
                  <ellipse cx="31.6848" cy="31.3243" rx="31.6848" ry="31.3243" transform="matrix(1 0 0 -1 20.8418 386.931)" fill="#F20008"/>
                  <ellipse cx="31.6848" cy="31.3243" rx="31.6848" ry="31.3243" transform="matrix(1 0 0 -1 20.8418 386.931)" fill="url(#paint5_radial_2323_8297)" fill-opacity="0.89"/>
                </g>
              </g>
              <defs>
                <filter id="filter0_i_2323_8297" x="35.4995" y="159.201" width="63.3695" height="67.179" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4.53011"/>
                  <feGaussianBlur stdDeviation="14.1566"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2323_8297"/>
                </filter>
                <filter id="filter1_i_2323_8297" x="20.8418" y="0" width="63.3695" height="67.179" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4.53011"/>
                  <feGaussianBlur stdDeviation="14.1566"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2323_8297"/>
                </filter>
                <filter id="filter2_i_2323_8297" x="20.8418" y="324.282" width="63.3695" height="67.179" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4.53011"/>
                  <feGaussianBlur stdDeviation="14.1566"/>
                  <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
                  <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2323_8297"/>
                </filter>
                <linearGradient id="paint0_linear_2323_8297" x1="5.45786" y1="196.128" x2="47.0472" y2="196.128" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFD386"/>
                  <stop offset="0.3125" stop-color="#FBB23C"/>
                  <stop offset="0.659698" stop-color="#883709"/>
                  <stop offset="0.958352" stop-color="#E09227"/>
                </linearGradient>
                <radialGradient id="paint1_radial_2323_8297" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(75.0624 167.386) rotate(105.895) scale(56.6289 57.2805)">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </radialGradient>
                <linearGradient id="paint2_linear_2323_8297" x1="3.21326" y1="107.569" x2="39.1304" y2="120.871" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFD386"/>
                  <stop offset="0.3125" stop-color="#FBB23C"/>
                  <stop offset="0.659698" stop-color="#883709"/>
                  <stop offset="0.958352" stop-color="#E09227"/>
                </linearGradient>
                <radialGradient id="paint3_radial_2323_8297" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60.4047 8.18498) rotate(105.895) scale(56.6289 57.2805)">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </radialGradient>
                <linearGradient id="paint4_linear_2323_8297" x1="42.4277" y1="281.833" x2="-1.66251" y2="307.073" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFD386"/>
                  <stop offset="0.3125" stop-color="#FBB23C"/>
                  <stop offset="0.659698" stop-color="#883709"/>
                  <stop offset="0.958352" stop-color="#E09227"/>
                </linearGradient>
                <radialGradient id="paint5_radial_2323_8297" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(38.1562 41.4348) rotate(-121.866) scale(39.3451 39.7978)">
                  <stop stop-color="white"/>
                  <stop offset="1" stop-color="white" stop-opacity="0"/>
                </radialGradient>
              </defs>
            </svg>
          </button>
          <div class="slot-roulette">
            <div class="slot-roulette__top">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
            </div>
            <div class="slot-roulette__middle">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
            </div>
            <div class="slot-roulette__bottom">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
              <img src="@/assets/img/roulette-item-1.png"
                   srcset="@/assets/img/roulette-item-1@2x.png" alt="">
            </div>
          </div>
          <div class="slot-maskot-bg">
            <img src="@/assets/img/slot-bg.png" alt="">
          </div>
          <div class="slot-maskot">
            <img src="@/assets/img/slot-maskot.png"
                 srcset="@/assets/img/slot-maskot@2x.png 2x" alt="">
          </div>
        </div>
      </div>
    </section>

    <section id="winners">
      <div class="section-title-wrap">
        <div class="section-title section-title--middle-2">
          Победители
        </div>
      </div>
      <div class="winners-block">
        <div class="winners-block__gift">
          <div class="winners-block__img">
            <img src="@/assets/img/prizes-2.png" alt="">
          </div>
          <div class="winners-block__name">
            iPad 10.9
          </div>
          <div class="winners-block__type">
            Еженедельный
          </div>
        </div>
        <div class="winners-block__results">
          <ul class="winners-block-list">
            <li class="winners-block-list__item" v-for="winner in winners" :key="winner.drawingDate + winner.winner">
              <div class="winners-block-list__item__date">
                {{ formatDate(winner.drawingDate) }}
              </div>
              <div class="winners-block-list__item__name">
                {{ winner.winner.name }}
              </div>
              <div class="winners-block-list__item__phone">
                {{winner.winner.phone}}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="main-prize-winner" v-for="winner in mainWinners" :key="winner.drawingDate + winner.winner">
        <div class="section-title-wrap">
          <div class="section-title section-title--middle-3">
            Роял степ приз
          </div>
        </div>

        <div class="main-prize-info">
          <div class="fishka-2">
            <img src="@/assets/img/fishka-2.png"
                 srcset="@/assets/img/fishka-2@2x.png 2x" alt="">
          </div>
          <div class="main-prize-info__img">
            <img src="@/assets/img/main-prize-img.png"
                 srcset="@/assets/img/main-prize-img@2x.png 2x" alt="">
          </div>
          <div class="main-prize-info__detail">
            <div class="main-prize-info__detail__title">
              {{ winner.prize.title }}
            </div>
            <div class="main-prize-info__detail__name">
              <span>{{ winner.winner.name }}</span>
            </div>
            <div class="main-prize-info__detail__phone">
              {{winner.winner.phone}}
            </div>
          </div>
          <div class="main-prize-info__maskot">
            <img src="@/assets/img/main-prize-maskot.png"
                 srcset="@/assets/img/main-prize-maskot@2x.png 2x" alt="">
          </div>
        </div>
      </div>
    </section>

    <section id="faq">
      <div class="section-title-wrap">
        <div class="section-title section-title--small">
          FAQ
        </div>
      </div>

      <div class="faq">
        <div class="faq-item" v-for="faq in faq" :key="faq">
          <button class="faq-item__btn" @click="toggleAccordion">
            {{ faq.question }}
          </button>
          <div class="faq-item__text" v-html="faq.answer"></div>
        </div>
      </div>
    </section>

    <section id="contact">
      <div class="maskot-2">
        <img src="@/assets/img/maskot-2.png"
             srcset="@/assets/img/maskot-2@2x.png 2x" alt="">
      </div>
      <div class="section-title-wrap">
        <div class="section-title section-title--middle-3">
          Напишите нам
        </div>
      </div>

      <h3>Если у Вас остались вопросы, задайте их нам.</h3>

      <div class="contact-form">
        <form @submit.prevent="sendFeedback">
          <div v-if="showSuccessMessage" class="success-message">
            Сообщение успешно отправлено
          </div>
          <div class="field-row" data-cols="2">
            <div class="field-item">
              <label for="name">Имя</label>
              <input type="text" v-model="form.name" id="name" placeholder="Введите имя" required maxlength="255">
            </div>
            <div class="field-item">
              <label for="email">Email</label>
              <input type="email" v-model="form.email" id="email" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" placeholder="example@mail.com" required maxlength="255">
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label for="text">Сообщение</label>
              <textarea v-model="form.text" id="text" placeholder="Введите сообщение" required maxlength="1000"></textarea>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <label class="field-item-checkbox">
                <input type="checkbox" required>
                Согласен(на) на обработку <a href="#">персональных данных</a>
              </label>
            </div>
          </div>
          <div class="field-row" data-cols="1">
            <div class="field-item">
              <button type="submit" class="button button-red">Отправить</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import {fetchFAQ, fetchSettings, fetchWinners, sendFeedback} from '@/api';

function getDeclension(number, words) {
  const cases = [2, 0, 1, 1, 1, 2];
  return words[
      number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
}

export default {
  name: 'MainContent',
  data() {
    return {
      form: {
        email: '',
        name: '',
        text: ''
      },
      showSuccessMessage: false,
      faq: [],
      winners: [],
      mainWinners: [],
      activeSlotIndex: 0,
      data: null,
      loading: true,
      error: null,
      startDate: null,
      endDate: null,
      currentTime: new Date(),
      state: '',
      timerInterval: null
    };
  },
  computed: {
    timeUntilStart() {
      return this.calculateTimeDifference(this.currentTime, this.startDate);
    },
    timeUntilEnd() {
      return this.calculateTimeDifference(this.currentTime, this.endDate);
    }
  },
  async created() {
    this.loadFAQ();
    this.loadWinners();
    this.loadMainWinners();
    try {
      this.data = await fetchSettings(); // Используем функцию fetchSettings из api.js
      this.startDate = new Date(this.data.startDate);
      this.endDate = new Date(this.data.endDate);

      this.determineTimerState();
      this.startTimer();
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async sendFeedback() {
      const { email, name, text } = this.form;

      try {
        await sendFeedback(email, name, text);
        this.clearForm();
        this.showSuccessMessage = true;
      } catch (error) {
        console.error('Error sending feedback:', error);
      }
    },
    clearForm() {
      this.form.email = '';
      this.form.name = '';
      this.form.text = '';
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async loadWinners() {
      try {
        const data = await fetchWinners();
        this.winners = data.results.filter(winner => !winner.prize.main);
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async loadMainWinners() {
      try {
        const data = await fetchWinners();
        this.mainWinners = data.results.filter(winner => winner.prize.main);
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('ru-RU', options);
    },
    slotBtn() {
      this.activeSlotIndex = 1;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.activeSlotIndex = (this.activeSlotIndex + 1) % 3;
      }, 50);
      setTimeout(() => {
        clearInterval(this.interval);
      }, 50);
    },

    calculateTimeDifference(start, end) {
      const difference = end.getTime() - start.getTime();
      if (difference <= 0) return {
        days: {digit1: 0, digit2: 0, label: 'дней'},
        hours: {digit1: 0, digit2: 0, label: 'часов'},
        minutes: {digit1: 0, digit2: 0, label: 'минут'}
      };

      const seconds = Math.floor(difference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      const remainingHours = hours % 24;
      const remainingMinutes = minutes % 60;

      const daysWord = getDeclension(days, ['день', 'дня', 'дней']);
      const hoursWord = getDeclension(remainingHours, ['час', 'часа', 'часов']);
      const minutesWord = getDeclension(remainingMinutes, ['минута', 'минуты', 'минут']);

      return {
        days: {digit1: Math.floor(days / 10), digit2: days % 10, label: daysWord},
        hours: {digit1: Math.floor(remainingHours / 10), digit2: remainingHours % 10, label: hoursWord},
        minutes: {digit1: Math.floor(remainingMinutes / 10), digit2: remainingMinutes % 10, label: minutesWord}
      };
    },
    determineTimerState() {
      const now = this.currentTime.getTime();
      const start = this.startDate.getTime();
      const end = this.endDate.getTime();

      if (now < start) {
        this.state = 'beforeStart';
      } else if (now >= start && now <= end) {
        this.state = 'during';
      } else {
        this.state = 'afterEnd';
      }
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.currentTime = new Date();
        this.determineTimerState();
      }, 1000);
    },
    beforeUnmount() {
      clearInterval(this.timerInterval);
    }
  }
}
</script>

<style scoped></style>
