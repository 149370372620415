<template>
  <header class="header">
    <a href="/" class="header-logo">
      <img src="@/assets/img/logo.svg" alt="">
    </a>
    <nav>
      <ul class="header-nav">
        <li class="header-nav-item">
          <a href="#" @click.prevent="scrollToSection('how-register')" class="header-nav-item__link">Как принять участие</a>
        </li>
        <li class="header-nav-item">
          <a href="#" @click.prevent="scrollToSection('prizes')" class="header-nav-item__link">Призы</a>
        </li>
        <li class="header-nav-item">
          <a href="#" @click.prevent="scrollToSection('winners')" class="header-nav-item__link">Победители</a>
        </li>
        <li class="header-nav-item">
          <a href="#" @click.prevent="scrollToSection('faq')" class="header-nav-item__link">FAQ</a>
        </li>
        <li class="header-nav-item">
          <a href="#" @click.prevent="scrollToSection('contact')" class="header-nav-item__link">Обратная связь</a>
        </li>
      </ul>
    </nav>
    <div class="account">
      <button class="button button-yellow">Личный кабинет</button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppHeader',
  methods: {
    scrollToSection(sectionId) {
      const headerHeight = document.querySelector('.header').offsetHeight; // замените '.header' на селектор вашей шапки
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
          top: sectionTop - headerHeight,
          behavior: 'smooth'
        });
      }
    }
  }
}
</script>

<style></style>